/* eslint-disable jsx-a11y/label-has-associated-control */
//eslint-disable-next-line
import React, { useState } from "react"
import { Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import axios from "axios"
import { useSelector } from "react-redux"
import { navigate } from "gatsby-link"

const Newsletter = ({
  data: {
    gqlData: { newsletterSection },
  },
}) => {
  const { language } = useSelector(state => state.global)
  const [sectionTitle1, sectionTitle2] =
    newsletterSection.title[language].split("/")
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [isLoading, setLoader] = useState(false)
  const [formValues, setFormvalue] = useState({
    name: "",
    email: "",
    page: "Blog",
    typeOfInquirer: "Customer",
  })
  const handleInputChange = ({ name, value }) => {
    setFormvalue({ ...formValues, [name]: value })
  }
  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  const formValidation = {
    name: {
      required: "First Name is Required",
    },
    email: {
      required: "Email is Required",
      pattern: {
        value: emailRegex,
        message: language === 'sv' ? "Ogiltig e-postadress" : "Invalid email address"
      },
    },
  }

  const formSubmit = e => {
    setLoader(true)
    const formData = new FormData()
    const updatedFormValues = {
      ...formValues,
      page: "Blog Details Newsletter",
      typeOfInquirer: "Customer",
      ...JSON.parse(localStorage.getItem("utmTags")),
    }
    Object.keys(updatedFormValues).forEach(key => {
      formData.append(key, updatedFormValues[key])
    })

    const URL =
      "https://script.google.com/macros/s/AKfycbyjJAN-x159oGCCdFmJJJE0_JYkyfFX7mPoracLxDVkqfSlFVr9DmL-zT3xnWMsX5Uj8w/exec"
    return axios(URL, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        setLoader(false)
        navigate("/thankyou")
        setFormvalue({
          name: "",
          email: "",
          page: "Blog",
          typeOfInquirer: "Customer",
        })
      })
      .catch(error => {
        console.log({ error })
      })
  }
  return (
    <section className="connect_block blog_detail">
      <div className="container p-0">
        <h2>
          {sectionTitle1}
          <span className="txtbrk">{sectionTitle2}</span>
        </h2>
        <div className="connect_grey">
          <div className="connect_box">
            <div className="containerr">
              <section className="mutistep_form w-100">
                <div className="phoneclr p-0">
                  <div className="row text_side p-0">
                    <div className="txt_block">
                      <div className="lftbrdr">
                        <h4 className="p-0">
                          {newsletterSection.form.title[language]}
                        </h4>
                        <p>{newsletterSection.form.subTitle[language]}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <form id="regForm" onSubmit={handleSubmit(formSubmit)}>
                  <div className="tab" style={{ display: "block" }}>
                    <div className="form_flx">
                      {newsletterSection.form.fields.map(field => (
                        <div className="inp_one">
                          <label>{field.label[language]}</label>
                          <input
                            placeholder=""
                            name={field.name}
                            {...register(
                              `${field.name}`,
                              { ...formValidation[field.name], required: field.errormsg[language] }
                            )}
                            onChange={({ target }) => handleInputChange(target)}
                          />
                          <p className="invalidd" style={{ display: "block" }}>
                            {errors[field.name] && errors[field.name].message}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="btn_field">
                    <button type="submit" className="blogbtn">
                      {isLoading ? (
                        <Spinner animation="border" size="sm" variant="light" />
                      ) : (
                        <>
                          {newsletterSection.buttonText[language]}
                          <i className="arrow" />
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
